import { Button, Stack } from "@mui/material";
import React from "react";
import { satrang24TicketLinks } from "../../seeds/satrang.page";
import { useNavigate } from "react-router-dom";
import { isDesktop, scrollToTop } from "../../utils/utils";

const ButtonRow = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/sponsor-us`);
    scrollToTop();
  };
  const isDesktopView = isDesktop();
  return (
    <Stack
      direction={isDesktopView ? "row" : "column"}
      spacing={2}
      sx={{
        my: 2,
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          window.open(satrang24TicketLinks);
        }}
        fullWidth
      >
        Buy tickets for Satrang 2024
      </Button>
      <Button variant="outlined" onClick={handleClick} fullWidth>
        Become our sponsor
      </Button>
    </Stack>
  );
};

export default ButtonRow;
