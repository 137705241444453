import React from "react";
import { Box, Stack } from "@mui/material";
import SponsorsBar from "../components/SponsorsBar";
import ImageCarousel from "../general-components/ImageCarousel";
import SatrangDescription from "../components/satrang/SatrangDescription";
import Satrang24 from "../components/satrang/satrang24/Satrang24";
import Satrang23 from "../components/satrang/satrang23/Satrang23";
import ButtonRow from "../components/satrang/ButtonRow";

const Satrang = (props) => {
  const { sponsorsData, satrangImageCarouselImages } = props;

  return (
    <>
      <Box sx={{ px: [2, 20], py: [2, 5] }}>
        <Stack spacing={2}>
          <ImageCarousel images={satrangImageCarouselImages} />

          <Box sx={{ my: 5 }}>
            <ButtonRow />
          </Box>
          <SatrangDescription />
          <Box sx={{ my: 5 }}>
            <ButtonRow />
          </Box>
          <Satrang24 />
          <Satrang23 />
        </Stack>
      </Box>
      <Box sx={{ mt: 5 }}>
        <SponsorsBar sponsorsData={sponsorsData} />
      </Box>
    </>
  );
};

export default Satrang;
