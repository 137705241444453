import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";

import EventCard from "./EventCard";
import Loading from "../general-components/Loading";

import { scrollToTop } from "../utils/utils";
import { callAnalytics } from "../utils/amplitude";

const ShowMoreBox = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "background.paper",
        color: "text.primary",
        cursor: "pointer",
      }}
    >
      <Typography variant="body1">Show More</Typography>
    </Box>
  );
};

const Carousel = (props) => {
  const { list } = props;

  const rows = list;
  const navigate = useNavigate();

  const handleClick = (eventLink) => {
    callAnalytics('buy now clicked for event');
    window.open(eventLink);
  };

  const navigateToEventsPage = () => {
    callAnalytics('show more clicked for events');
    navigate(`/events`);
    scrollToTop();
  };

  if (typeof rows === "undefined" || typeof rows === "null") return <Loading />;

  const satrangEvent =
    rows &&
    rows.filter((row) => {
      if (row.title.includes("Satrang")) {
        return row;
      }
    })[0];

  // const regularEvents =
  //   rows &&
  //   rows.filter((row) => {
  //     if (!row.title.includes("Satrang")) {
  //       return row;
  //     }
  //   });
  const regularEvents = rows;

  return (
    <Box
      sx={{
        width: "inherit",
        bgcolor: "background.paper",
      }}
      textAlign="center"
    >
      <Typography variant="h2" sx={{ mb: "16px" }}>
        Upcoming Events
      </Typography>
      <Box
        sx={{
          m: "auto",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              background: "none !important",
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 1 },
            },
            mx: ["auto", 5],
          }}
        >
          {regularEvents &&
            regularEvents.slice(0, 4).map((row) => {
              let tmpLabel = (
                <EventCard
                  title={row.title}
                  date={row.date}
                  imageLink={row.imageLink}
                  description={row.description}
                  primaryActionLabel={"Buy Tickets"}
                  handleClick={() => handleClick(row.link)}
                />
              );
              return (
                <Tab key={row.title} label={tmpLabel} disableRipple={true} />
              );
            })}
          <Tab
            sx={{
              cursor: "pointer",
            }}
            key={"show more tab"}
            label={<ShowMoreBox />}
            onClick={navigateToEventsPage}
          />
        </Tabs>
      </Box>
      {/* <Box sx={{ mt: "16px" }} />
      {satrangEvent && (
        <EventCard
          title={satrangEvent.title}
          date={satrangEvent.date}
          imageLink={satrangEvent.imageLink}
          description={satrangEvent.description}
          handleClick={() => handleClick(satrangEvent.link)}
          fullwidth
          imgHeight={600}
          primaryActionLabel={"Learn more"}
          containImage
        />
      )} */}
    </Box>
  );
};

export default Carousel;
